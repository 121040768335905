import { LinearProgress, TextField } from '@material-ui/core';
import { convertToRaw, EditorState } from 'draft-js';
import React, { SetStateAction, useEffect, useState, useMemo } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { IEntry, IModal, ModalVariantsEnums } from './IEditor';
import { ClearIcon } from '../Icons/ClearIcon';
import EditIcon from '@material-ui/icons/Edit';
import '../../styles/css/main_modals.css';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  deleteEntry,
  getEntries,
  getEntriesVariables,
  patchEntry,
  postEntry,
} from '../../api/entriesService';
import {
  convertEditorStateToTranscript,
  convertTranscriptToEditorState,
  mergeNewWordDataToLiveWordDataParagraphs,
} from '../../shared/DataConverters';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import SimpleEntriesEditor, { EntryVarData } from './SimpleEntriesEditor';
import { useSnackbar } from 'notistack';

interface IProps {
  setModal: React.Dispatch<SetStateAction<IModal>>;
}

const EntriesModalContent = ({ setModal }: IProps) => {
  const queryClient = useQueryClient();

  const {
    data: entriesData,
    error: entriesError,
    isLoading: isLoadingEntries,
  } = useQuery('entries', getEntries, { refetchOnWindowFocus: false });

  const {
    mutate: deleteEntryFn,
    data: dataDelete,
    error: errorDelete,
    isLoading: isLoadingDelete,
  } = useMutation(deleteEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries('entries');
    },
  });

  const {
    mutate: updateEntry,
    data: dataUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation(patchEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries('entries');
    },
  });

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const {
    mutate: addNewEntry,
    data: dataAdd,
    error: errorAdd,
    isLoading: isLoadingAdd,
  } = useMutation(postEntry, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('entries');
    },
  });

  const [handleSingleEntryMode, setHandleSingleEntryMode] = useState<{
    show: boolean;
    selectedEntry: IEntry | null;
  }>({ show: false, selectedEntry: null });
  const [selectedEntryDesc, setSelectedEntryDesc] = useState('');

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const showConfirmOpts = handleSingleEntryMode.show || handleSingleEntryMode.selectedEntry;

  useEffect(() => {
    if (!dataAdd) return;

    enqueueSnackbar('Vnos uspešno dodan.', { variant: 'success' });

    setHandleSingleEntryMode({ show: false, selectedEntry: null });
  }, [dataAdd]);

  useEffect(() => {
    if (!dataUpdate) return;

    enqueueSnackbar('Vnos uspešno posodobljen.', { variant: 'success' });

    setHandleSingleEntryMode({ show: false, selectedEntry: null });
  }, [dataUpdate]);

  useEffect(() => {
    if (!dataDelete) return;

    enqueueSnackbar('Vnos uspešno izbrisan.', { variant: 'success' });

    setHandleSingleEntryMode({ show: false, selectedEntry: null });
  }, [dataUpdate]);

  useEffect(() => {
    if (errorAdd || errorUpdate || errorDelete) {
      enqueueSnackbar('Prišlo je do napake pri posodabljanju vnosa', { variant: 'error' });
    }
  }, [errorAdd, errorDelete, errorUpdate]);

  useEffect(() => {
    const { show, selectedEntry } = handleSingleEntryMode;
    if (selectedEntry && !show) {
      setSelectedEntryDesc(selectedEntry.description);

      const { data } = mergeNewWordDataToLiveWordDataParagraphs([], selectedEntry.content, {});

      const newEditorState = convertTranscriptToEditorState(undefined, false, data, [], true);

      setEditorState(newEditorState);

      setHandleSingleEntryMode({ ...handleSingleEntryMode, show: true });
    }
  }, [handleSingleEntryMode]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleClickConfirm = () => {
    const { show, selectedEntry } = handleSingleEntryMode;
    if (show) {
      if (!selectedEntryDesc.length || !editorState.getCurrentContent().getPlainText().length) {
        enqueueSnackbar('Vnosna polja ne smejo biti prazna.', { variant: 'error' });
        return;
      }

      const { liveWordTranscript } = convertEditorStateToTranscript(editorState, {
        toLiveWordData: true,
        fromEntries: true,
      });
      const raw = convertToRaw(editorState.getCurrentContent());

      let finalVersion: any[] = [];
      liveWordTranscript.forEach((block, i, arr) => {
        const styleRanges = raw.blocks[i].inlineStyleRanges;

        if (block.words.length === 0) {
          finalVersion.push({ text: '<nl>', confidence: 1, startTime: 0, endTime: 0 });
        } else {
          const actualWords: string[] = [];
          block.words[0].text.split(' ').forEach((newWord) => {
            if (newWord.startsWith('{') && !newWord.endsWith('}') && newWord.includes('}')) {
              newWord.trim();
              const idx = newWord.indexOf('}');
              const updatedWords = [newWord.substring(0, idx + 1), newWord.substring(idx + 1)];
              actualWords.push(updatedWords[0]);
              actualWords.push(updatedWords[1]);
            } else {
              actualWords.push(newWord);
            }
          });

          let currentWordOffset = 0;

          actualWords.forEach((word, k) => {
            if (k !== 0) {
              currentWordOffset += actualWords[k - 1].length + 1;
            } else {
            }

            let inlineStyles: string[] = [];
            styleRanges.forEach((range) => {
              const condA =
                range.offset >= currentWordOffset && range.offset < currentWordOffset + word.length;
              const condB =
                range.offset + range.length > currentWordOffset &&
                range.offset + range.length <= currentWordOffset + word.length;
              const condC =
                range.offset <= currentWordOffset &&
                range.offset + range.length >= currentWordOffset + word.length;

              if (condA || condB || condC) {
                inlineStyles = [...inlineStyles, range.style];
              }
            });

            finalVersion.push({
              text: word,
              spaceBefore: true,
              inlineStyles,
            });
          });
        }
        if (arr[i + 1] && block.words.length !== 0) {
          finalVersion.push({ text: '<nl>', spaceBefore: true, inlineStyles: [] });
        }
      });

      // console.log('finalVersion:', finalVersion);

      if (selectedEntry) {
        updateEntry({ description: selectedEntryDesc, content: finalVersion, id: selectedEntry.id });
        return;
      }

      if (!selectedEntry) {
        addNewEntry({ description: selectedEntryDesc, content: finalVersion });
        return;
      }
    }
  };

  const handleCancelButton = () => {
    if (handleSingleEntryMode.show) {
      setHandleSingleEntryMode({ show: false, selectedEntry: null });
      setSelectedEntryDesc('');
      setEditorState(() => EditorState.createEmpty());
      return;
    }
    setModal({ variant: ModalVariantsEnums.EXIT, show: false });
  };

  return (
    <div
      className="replace_words_modal_wrapper"
      style={{
        maxWidth: '100%',
        minWidth: '500px',
        minHeight: 550,
        height: '100%',
        // height: 'calc(100% - 200px)',
      }}
    >
      <div style={{ height: '100%' }}>
        <div
          className="replace_words_modal_selected_phrase"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: handleSingleEntryMode.show ? 5 : '',
            flexDirection: 'row',
          }}
        >
          <span style={{ position: 'relative' }}>
            {handleSingleEntryMode.show && handleSingleEntryMode.selectedEntry
              ? 'Posodobi vnos'
              : handleSingleEntryMode.show
              ? 'Nov vnos'
              : 'Vnosi'}
          </span>
          {!handleSingleEntryMode.show && (
            <button
              onClick={() => {
                setHandleSingleEntryMode({ selectedEntry: null, show: true });
              }}
              className="addpronunciation_btn"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
              }}
            >
              <div />
              <div />
            </button>
          )}
        </div>
        {!handleSingleEntryMode.show && (
          <>
            {isLoadingEntries && (
              <LinearProgress
                style={{
                  height: 2,
                  width: '100%',
                }}
                color="secondary"
              />
            )}
          </>
        )}
        {handleSingleEntryMode.show && (
          <HandleSingleEntry
            editorState={editorState}
            setEditorState={setEditorState}
            handleSingleEntryMode={handleSingleEntryMode}
            setHandleSingleEntryMode={setHandleSingleEntryMode}
            selectedEntryDesc={selectedEntryDesc}
            setSelectedEntryDesc={setSelectedEntryDesc}
          />
        )}
        {!handleSingleEntryMode.show && (
          <EntriesMode
            entriesError={entriesError}
            deleteEntryFn={deleteEntryFn}
            entriesData={entriesData && entriesData.data}
            setHandleSingleEntryMode={setHandleSingleEntryMode}
          />
        )}
      </div>

      <div
        className="modal_buttons_wrapper"
        style={{ position: 'absolute', margin: 0, right: 30, bottom: 30 }}
      >
        <button
          type="button"
          onClick={handleCancelButton}
          className="modal_button_disaggre"
          style={
            handleSingleEntryMode.selectedEntry === null && !handleSingleEntryMode.show
              ? { marginRight: 0 }
              : {}
          }
        >
          {handleSingleEntryMode.show && handleSingleEntryMode.selectedEntry
            ? 'Prekliči'
            : handleSingleEntryMode.show
            ? 'Nazaj'
            : 'Zapri'}
        </button>
        {showConfirmOpts && (
          <button
            type="button"
            onClick={handleClickConfirm}
            className="modal_button_aggre"
            disabled={isLoadingAdd || isLoadingEntries || isLoadingUpdate}
          >
            {handleSingleEntryMode.show && handleSingleEntryMode.selectedEntry
              ? 'Posodobi'
              : handleSingleEntryMode.show
              ? 'Dodaj'
              : 'Izberi'}
          </button>
        )}
      </div>
    </div>
  );
};

function HandleSingleEntry({
  setEditorState,
  editorState,
  handleSingleEntryMode,
  selectedEntryDesc,
  setSelectedEntryDesc,
  setHandleSingleEntryMode,
}: {
  selectedEntryDesc: string;
  setSelectedEntryDesc: React.Dispatch<React.SetStateAction<string>>;
  editorState: any;
  setHandleSingleEntryMode: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      selectedEntry: IEntry | null;
    }>
  >;
  setEditorState: any;
  handleSingleEntryMode: {
    show: boolean;
    selectedEntry: IEntry | null;
  };
}) {
  const fnRef = useRef<HTMLInputElement>(null);

  const {
    data: entriesVariablesData,
    error: entriesVariablesError,
    isLoading: isLoadingEntriesVariables,
  } = useQuery('entries-variables', getEntriesVariables, { refetchOnWindowFocus: false });

  const entriesVars: EntryVarData[] = useMemo(() => {
    return entriesVariablesData?.data
      ? entriesVariablesData.data.map(({ code, description, value }) => {
          return {
            name: code,
            code,
            description,
            value,
          };
        })
      : [];
  }, [entriesVariablesData]);

  return (
    <div style={{ height: '100%' }}>
      <TextField
        value={selectedEntryDesc}
        FormHelperTextProps={{
          className: 'helper_text',
        }}
        inputRef={fnRef}
        style={{
          width: '100%',
          fontFamily: 'Roboto',
          marginTop: 20,
          marginBottom: 20,
        }}
        placeholder="Vnesite opis/ime vnosa"
        margin="dense"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSelectedEntryDesc(e.target.value);
        }}
      />
      <div style={{ height: 'calc(100% - 200px)' }}>
        <SimpleEntriesEditor
          editorState={editorState}
          setEditorState={setEditorState}
          entriesVars={entriesVars}
        />
      </div>
    </div>
  );
}

function EntriesMode({
  entriesData,
  deleteEntryFn,
  setHandleSingleEntryMode,
  entriesError,
}: {
  entriesData: IEntry[];
  deleteEntryFn: any;
  setHandleSingleEntryMode: any;
  entriesError: any;
}) {
  if (entriesError) return <span>Prišlo je do napake v povezavi z zalednim sistemom</span>;
  if (entriesData && entriesData.length === 0) return <span>Trenutno ni na voljo nobenega vnosa</span>;
  return (
    <div
      className="show_scrollbar"
      style={{ width: '100%', overflowY: 'auto', height: '100%', maxHeight: 'calc(100% - 150px)' }}
    >
      {entriesData &&
        entriesData.length &&
        entriesData.map((r, i) => (
          <div
            key={i.toString()}
            style={{
              color: '#333333',
              width: '100%',
              position: 'relative',
              marginTop: 10,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '45px',
              whiteSpace: 'pre',
            }}
          >
            <div
              style={{
                fontFamily: 'Roboto',
                fontSize: '18px',
                minHeight: 20,
              }}
            >
              {r.description}
            </div>
            <div>
              <button
                onClick={() => {
                  setHandleSingleEntryMode({ show: false, selectedEntry: r });
                }}
                style={{
                  color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  right: 30,
                  transform: 'translateY(-50%)',
                }}
              >
                <EditIcon width="18px" />
              </button>
              <button
                onClick={() => {
                  deleteEntryFn(r.id);
                }}
                style={{
                  color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                }}
              >
                <ClearIcon width="18px" />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default EntriesModalContent;
