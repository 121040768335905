import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

interface IProps {
  open: boolean;
  handleClose: any;
  deleteSessionCallback: any;
}

const DeletionConfirmation = ({ open, handleClose, deleteSessionCallback }: IProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Potrditev brisanja'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Si prepričan da želiš izbrisati ta projekt? To dejanje je nepovratno.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Ne
        </Button>
        <Button onClick={() => deleteSessionCallback()} color="primary" autoFocus>
          Izbriši
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletionConfirmation;
