import React, { useEffect, useState } from 'react';
import SettingsDrawer from './SettingsDrawer';
import FooterInfo from './FooterInfo';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import {
  setAudioInfo,
  setTbFormat,
  setSessionName,
  setValidRedirect,
  setEditorMode,
  setHomeFlowState,
} from '../../redux/actions/actions';
import { EditorModeEnums, IStore, UserRoleEnums } from '../../redux/store/IStore';
import separateExtension from '../../shared/separateExtension';
import { isAuthenticated } from '../../api/AuthenticationService';
import '../../styles/css/home.css';
import useAudioSocket from '../../hooks/useAudioSocket';
import { motion } from 'framer-motion';
import { readFile } from '../../shared/readFile';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useWs from '../../hooks/useWs';
import { getConfiguration, getSttStatus } from '../../api/configurationService';

enum FileExtensions {
  WAV = 'wav',
  TB = 'tb',
}

export const checkIfSelectedOptionsAreAvailable = async () => {
  try {
    const [{ data: cfg }, { data: sttStatus }] = await Promise.all([getConfiguration(), getSttStatus()]);
    const { stt } = cfg;
    const { frameworks } = sttStatus;

    if (stt && frameworks) {
      const transcriptionFramework = stt.framework.value;
      const transcriptionLanguage = stt.language.value;
      const transcriptionDomain = stt.domain.value;
      const transcriptionModelVersion = stt.model.value;

      const frameWorkStatus = frameworks.find((f) => f.code === transcriptionFramework);
      if (!frameWorkStatus || !frameWorkStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrano ogrodje trenutno ni na voljo.' };
      }

      const langStatus = frameWorkStatus.languages.find((l) => l.code === transcriptionLanguage);
      if (!langStatus || !langStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrani jezik trenutno ni na voljo.' };
      }

      const domainStatus = langStatus.domains.find((d) => d.code === transcriptionDomain);
      if (!domainStatus || !domainStatus.isAllowed) {
        return { isAvailable: false, message: 'Izbrana domena trenutno ni na voljo.' };
      }

      const modelStatus = domainStatus.models.find((m) => m.code === transcriptionModelVersion);
      if (!modelStatus || !modelStatus.isAllowed || !modelStatus.isAvailable) {
        return { isAvailable: false, message: 'Izbrana verzija trenutno ni na voljo.' };
      }

      return { isAvailable: true, message: '' };
    } else {
      return {
        isAvailable: false,
        message: 'Preverjanje verzije oz. modela ni uspelo. Prosimo poskusite še enkrat.',
      };
    }
  } catch (err) {
    console.log(err);
    return {
      isAvailable: false,
      message: 'Preverjanje verzije oz. modela ni uspelo. Prosimo poskusite še enkrat.',
    };
  }
};

const Home = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const user = useSelector((state: IStore) => state.user);
  const fontsLoaded = useSelector((state: IStore) => state.fontsLoaded);
  const liveFlowInProgress = useSelector((state: IStore) => state.homeFlowStates.liveFlowInProgress);
  const uploadFlowInProgress = useSelector((state: IStore) => state.homeFlowStates.uploadFlowInProgress);
  const history = useHistory();
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const uploadLabelRef = React.useRef<HTMLLabelElement>(null);
  const progressRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { startRecordingLive, startSendingChunks } = useAudioSocket();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected } = useWs();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (!user) {
      setShowSettings(true);
    }
  }, [user, isMounted]);

  const handleNotAuthFlow = () => {
    setShowSettings(true);

    enqueueSnackbar('Pred uporabo storitev se morate avtenticirati.', { variant: 'error' });
  };

  const handleLiveClick = async () => {
    if (liveFlowInProgress || uploadFlowInProgress) return;
    dispatch(setHomeFlowState({ liveFlowInProgress: true }));

    if (!isAuthenticated() || !user || !user.isAuthenticated) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    if (!user.userRoles.includes(UserRoleEnums.LIVE_STREAMER)) {
      enqueueSnackbar('Za snemanje v živo nimate dovoljenja.', { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    /*const { isAvailable, message } = await checkIfSelectedOptionsAreAvailable();
    if (!isAvailable) {
      enqueueSnackbar(message, { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }*/

    await startRecordingLive({ redirectToEditor: true });
  };

  const handleFileChange = async (event: any) => {
    console.log('File changed');
    console.log(event);
    console.log(event.target.files);

    console.log(`uploadFlowInProgress is ${uploadFlowInProgress ? '' : 'not'} true`);
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));
    if (!isAuthenticated() || !user) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    console.log(user);
    if (!user.userRoles.includes(UserRoleEnums.FILE_UPLOADER)) {
      enqueueSnackbar('Za nalaganje nimate dovoljenja', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    /*const { isAvailable, message } = await checkIfSelectedOptionsAreAvailable();
    if (!isAvailable) {
      enqueueSnackbar(message, { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }*/

    const files: FileList = event.target.files;

    let tbIndex: number | null = null;
    let wavIndex: number | null = null;

    // Check for existence of files
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(file.name);

        // Found a .tb file
        if (fileExtension === FileExtensions.TB) {
          // If it has not yet been found its ok
          if (tbIndex === null) {
            tbIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('You can only upload 1 .tb file!', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }

        // Found a .wav file
        if (fileExtension === FileExtensions.WAV) {
          // If it has not yet been found its ok
          if (wavIndex === null) {
            wavIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('You can only upload 1 .wav file!', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Error
    // if (tbIndex === null && wavIndex === null) {

    //   // resetUploadButton();
    //   setIsUploading(false);
    //   setDidUpload(false);
    //   return;
    // }

    // Error
    if (tbIndex !== null && wavIndex === null) {
      enqueueSnackbar('Please, upload the corresponding .wav file as well!', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    // Flow for only wav
    else if (tbIndex === null) {
      //@ts-ignore
      const file = files.item(wavIndex);
      if (file) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(file.name);
        dispatch(setSessionName(fileName));

        let loadedWavFile: any;

        try {
          loadedWavFile = await readFile(file);
        } catch (err) {
          console.log(err);

          enqueueSnackbar('Z zvočnim posnetkom je nekaj narobe. Prosimo izberite drugega.', {
            variant: 'error',
          });
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        // const allowedChannels = [1];
        // if (!allowedChannels.includes(waveFile.numChannels)) {
        //   // setLoadedWavFile(undefined);

        //   return;
        // }

        // const allowedSampleRates = [16000];
        // if (!allowedSampleRates.includes(waveFile.sampleRate)) {
        //   // setLoadedWavFile(undefined);

        //   return;
        // }

        startSendingChunks(loadedWavFile);
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Flow for both .tb and .wav
    else if (wavIndex !== null && tbIndex !== null) {
      //@ts-ignore
      const tbFile = files.item(tbIndex);
      //@ts-ignore
      const wavFile = files.item(wavIndex);

      let tbFinished = false;
      let wavFinished = false;

      if (tbFile && wavFile) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(wavFile.name);
        const tbReader = new FileReader();
        tbReader.onload = (e: any) => {
          tbFinished = true;
          let tbFormat: any = JSON.parse(e.target.result);

          if (tbFormat.transcript && tbFormat.transcript[0] && tbFormat.transcript[0].text) {
            tbFormat = { ...tbFormat, transcript: [[...tbFormat.transcript]] };
          } else if (tbFormat.blocks) {
            tbFormat = { editorStateStringified: JSON.stringify(tbFormat) };
          }
          dispatch(setTbFormat(tbFormat));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          wavFinished && history.push('/editor');
        };

        //@ts-ignore
        tbReader.readAsText(tbFile);

        const wavReader = new FileReader();
        wavReader.onload = (e: any) => {
          wavFinished = true;
          dispatch(setSessionName(fileName));
          dispatch(setAudioInfo({ url: e.target.result, loadNew: true }));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          tbFinished && history.push('/editor');
        };
        //@ts-ignore
        wavReader.readAsDataURL(wavFile);
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }
  };

  const handleClickSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleOnHistoryClick = () => {
    if (uploadFlowInProgress || liveFlowInProgress) return;
    if (!isAuthenticated()) {
      handleNotAuthFlow();
      return;
    }

    history.push('/dashboard');
  };

  const checkIfCanceledInput = () => {
    if (!fileUploadRef.current) throw new Error('focus should not trigger when fileUploadRef is null!');

    //it is always empty
    if (fileUploadRef.current.value === '') {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      window.removeEventListener('focus', checkIfCanceledInput);
    }
  };

  const disabledLive = uploadFlowInProgress || liveFlowInProgress || isConnected;
  const disabledUpload = uploadFlowInProgress || liveFlowInProgress;

  const handleUploadLabelClick = async (event: any) => {
    event.preventDefault();
    if (uploadFlowInProgress || liveFlowInProgress) return;
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));

    if (!isAuthenticated()) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!user?.userRoles.includes(UserRoleEnums.FILE_UPLOADER)) {
      enqueueSnackbar('Za nalaganje nimate dovoljenja.', {
        variant: 'error',
      });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!fileUploadRef.current) {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    /*const { isAvailable, message } = await checkIfSelectedOptionsAreAvailable();
    if (!isAvailable) {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      enqueueSnackbar(message, {
        variant: 'error',
      });
      return;
    }*/

    window.addEventListener('focus', checkIfCanceledInput);
    fileUploadRef.current.value = '';
    fileUploadRef.current.click();
  };

  return (
    <>
      {/*<div id="test_click" style={{backgroundColor: '#FF000061'}}>
        <input ref={fileUploadRef} onChange={handleFileChange} type="file" id="fileElem" accept=".wav" style={{display: "none"}} />
        <button onClick={handleUploadLabelClick} id="fileSelect" style={{width: '100%', height: "50px"}}></button>
  </div>*/}
      <div className="home">
        <SettingsDrawer handleCloseSettings={handleCloseSettings} show={showSettings} />
        {fontsLoaded && (
          <motion.div
            className="buttons_container"
            initial={{ opacity: 0.2, x: '-2%' }}
            animate={{ opacity: 1, x: '0%', transition: { delay: 0.3 } }}
          >
            <div className="live_settings_container">
              <div className="live_settings_buttons">
                <button
                  className="button_live"
                  onClick={handleLiveClick}
                  style={{
                    cursor: disabledLive ? 'default' : 'pointer',
                    // opacity: disabledLive ? 0.8 : 1,
                    ...(uploadFlowInProgress ? { backgroundColor: '#833232' } : {}),
                  }}
                  disabled={disabledLive}
                >
                  {liveFlowInProgress ? <CircularProgress color="inherit" /> : <span>V ŽIVO</span>}
                </button>
                <button
                  onClick={handleUploadLabelClick}
                  style={{
                    cursor: disabledUpload ? 'default' : 'pointer',
                    // opacity: disabledUpload ? 0.8 : 1,
                    ...(liveFlowInProgress ? { backgroundColor: '#275d8c' } : {}),
                  }}
                  //htmlFor={authenticated ? "button-file" : undefined}
                  className="button_upload"
                  id="fileSelect"
                >
                  <div className="upload_tittle_container">
                    {uploadFlowInProgress ? <CircularProgress color="inherit" /> : <span>NALOŽI</span>}
                  </div>
                  <div id="progress" ref={progressRef} />

                  {/*<button id="fileSelect" style={{ display: 'none' }} />*/}
                </button>
                <input
                  ref={fileUploadRef}
                  onChange={handleFileChange}
                  type="file"
                  multiple
                  id="fileElem"
                  // accept=".wav" we should accept any extensions
                  style={{ display: 'none' }}
                />
              </div>
              <button
                className="button_history"
                onClick={handleOnHistoryClick}
                disabled={uploadFlowInProgress || liveFlowInProgress}
                style={{
                  cursor: uploadFlowInProgress || liveFlowInProgress ? 'default' : 'pointer',
                  opacity: uploadFlowInProgress || liveFlowInProgress ? 0.8 : 1,
                }}
              >
                ZGODOVINA
              </button>
            </div>
          </motion.div>
        )}
        {fontsLoaded && (
          <motion.div
            style={{
              position: 'absolute',
              right: '25px',
              bottom: '25px',
              left: 0,
              width: '100%',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <FooterInfo />
          </motion.div>
        )}
        <button
          className="button_menu_settings"
          onClick={handleClickSettings}
          style={{ width: 48, height: 48 }}
        >
          <MenuIcon fontSize="large" />
        </button>
      </div>
    </>
  );
};

export default Home;
