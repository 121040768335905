import React, { useState, useEffect } from 'react';
import { ColumnTypes, IFetchedData, IChipData, ISessionEntry } from './IDashboard';

import { useDispatch, useSelector } from 'react-redux';
import { setDashboardExpendedRow } from '../../redux/actions/actions';

import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
import TableRowAccordion from './TableRowAccordion';
import Chip from './ColouredChip';

import '../../styles/css/dashboard.css';
import { IStore } from '../../redux/store/IStore';

import { getSessionDetails } from '../../api/SessionsService';
import { useSnackbar } from 'notistack';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import IconButton from '@material-ui/core/IconButton';
import TableRowItem from './TableRowItem';
import ResizableRow from './ResizableRow';
import { SessionState } from '../DashboardHeader/ISearch';

interface IProps {
  row: ISessionEntry;
  handleRowClick: (id: number, sessionName: string) => void;
  headerOrder: any[];
}

const TableRow = ({ row, handleRowClick, headerOrder }: IProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState(row.name);
  const [chips, setChips] = useState<IChipData[]>(row.labels);
  const [sharedWithOthers, setSharedWithOthers] = useState(row.sharedWithOthers);

  const [fetchedData, setFetchedData] = useState<IFetchedData | null>(null);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const expandedRow = useSelector((state: IStore) => state.dashboardExpendedRow) as number;
  const showDeletedSessions = useSelector(
    (state: IStore) => state.dashboardFilterCommands.showDeletedSessions
  );
  const tableWidth = useSelector((state: IStore) => state.dashboardTableWidth) || 1200;

  useEffect(() => {
    setExpanded(expandedRow === row.id);
  }, [expandedRow, row.id]);

  const expandRowClick = (e) => {
    e.stopPropagation();
    if (expandedRow === row.id) setExpanded(!expanded);
    else dispatch(setDashboardExpendedRow(row.id));
  };

  const handleOnRowClick = () => {
    if (expanded) setExpanded(false);
    else if (row.status !== SessionState.FINISHED) enqueueSnackbar('Seja ki ni zaključena, ni na voljo za pregled.', {
      variant: 'error',
    });
    else handleRowClick(row.id, title);
  };

  useEffect(() => {
    if (!expanded) return;
    async function fetchMyAPI() {
      setIsFetching(true);
      getSessionDetails(row.id)
        .then(
          ([
            {
              data: {
                name,
                notes,
                labels,
                createdAt,
                recordedSeconds,
                updatedAt,
                numRecordings,
                isSaved,
                sources,
              },
            },
            {
              data: { summary },
            },
          ]) => {
            setFetchedData(() => ({
              summary,
              title: name,
              chips: labels,
              notes: notes ? notes : '',
              createdAt,
              recordedSeconds,
              updatedAt,
              numRecordings,
              isSaved,
              sources,
            }));
            setIsFetching(false);
          }
        )
        .catch(() => {
          setIsFetching(false);
          enqueueSnackbar('Ta seja trenutno ni na voljo', {
            variant: 'error',
          });
        });
    }

    fetchMyAPI();
  }, [expanded]);

  const stylePropsHeader = {};
  if (row.isDiscarded) stylePropsHeader['color'] = '#f55f6c';

  return (
    <>
      <div className={`row ${expanded ? 'row-active' : ''}`} onClick={handleOnRowClick}>
        <div className="row-extender-icon-wrapper">
          <IconButton style={{ height: '28px', width: '28px', zIndex: 2 }} onClick={expandRowClick}>
            <ExpandMoreRoundedIcon className={`row-extender-icon ${expanded ? 'extended' : ''}`} />
          </IconButton>
        </div>

        <div className="row-inner-wrapper" style={{ width: tableWidth + 'px' }}>
          <div className={`row-details-header ${expanded ? 'active' : ''}`} style={stylePropsHeader}>
            Podrobnosti {row.isDiscarded && ' (Izbrisana seja)'}
          </div>

          <div className={`row-attribute-wrapper ${expanded ? 'hidden' : ''}`}>
            {headerOrder.map((col, i) => {
              const data = row[col.id];
              if (col.type === ColumnTypes.LABELS) {
                return (
                  <ResizableRow attr={col.id} key={i}>
                    <div className="row-individual-attribute row-attr-labels">
                      {chips
                        .filter(({ isEnabled }) => isEnabled)
                        .slice(0, 3)
                        .map(({ isEnabled, label }) => (
                          <Chip
                            customColor={label.color}
                            key={label.id}
                            label={label.code}
                            variant={isEnabled ? 'default' : 'outlined'}
                            size="small"
                          />
                        ))}
                    </div>
                  </ResizableRow>
                );
              }

              return (
                <ResizableRow attr={col.id} key={i}>
                  <TableRowItem
                    type={col.type}
                    sources={row.sources || []}
                    data={col.type === ColumnTypes.TITLE ? title : data}
                    row={{ ...row, sharedWithOthers }}
                  />
                </ResizableRow>
              );
            })}
          </div>
        </div>
      </div>

      <div className="row-accordion-wrapper" style={{ width: `max(100%, ${tableWidth + 60}px)` }}>
        <Collapse in={expanded}>
          {isFetching ? (
            <div style={{ width: '100%', padding: 20 }}>
              <Skeleton variant="rect" width={'60%'} height={25} style={{ marginBottom: 20 }} />
              <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: 20 }} />
              <Skeleton variant="rect" width={'100%'} height={118} />
            </div>
          ) : fetchedData ? (
            <TableRowAccordion
              row={row}
              showSkeleton={isFetching}
              handleRowClick={handleRowClick}
              fetchedData={fetchedData}
              setParentTitle={setTitle}
              setParentChips={setChips}
              setSharedWithOthers={setSharedWithOthers}
            />
          ) : null}
        </Collapse>
      </div>
    </>
  );
};

export default TableRow;
