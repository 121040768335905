/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import {
  AnimButtonStatesEnum,
  EditorModeEnums,
  IStore,
  SpeakersModalTypeEnum,
} from '../../redux/store/IStore';

import {
  CurrentCommandModeEnums,
  IFinalBlock,
  ILastSaved,
  ILoopModeOpts,
  IModal,
  IReplaceWordsModalProps,
  ISpeaker,
  IWordDataLive,
  ModalVariantsEnums,
} from './IEditor';
import {
  setAudioInfo,
  setClickedTime,
  setEditorMode,
  setShowConfidence,
  setLoadContentToEditor,
  setTbFormat,
  setHeaderIsExtended,
  setSessionName,
  setValidRedirect,
  setCurrentTime,
  setAnimButtonState,
  setUploadedFileDuration,
  setManuallyUpdateEditorState,
  setSpeakersModal,
  setIsSessionDiscarded,
} from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import SelectionPopover from '../SelectionPopover/SelectionPopover';
import { Redirect, useHistory } from 'react-router-dom';
import useShortcuts from '../../hooks/useShortcuts';
import { convertFromRaw, convertToRaw, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import {
  customBlockStyleFn,
  decorator,
  getAllEntitiesKeysFromSelection,
  replaceSelectionWithNewText,
} from './helpers/EditorHelpers';
import useSelectionPopover from '../../hooks/useSelectionPopover';
import { IDictionaryModalProps } from '../DictionaryModal/IDictionaryModal';
import { convertEditorStateToTranscript, convertTranscriptToEditorState } from '../../shared/DataConverters';
import '../../styles/css/editor.css';
import { useBeforeunload } from 'react-beforeunload';
import { isAuthenticated } from '../../api/AuthenticationService';
import useCheckIfModelAvailable from '../../hooks/useCheckIfModelAvailable';
import useWs from '../../hooks/useWs';
import useAudioSocket from '../../hooks/useAudioSocket';
import useEditorRefs from '../../hooks/useEditorRefs';
import LiveEditor from './LiveEditor';
import useEditor from '../../hooks/useEditor';
import useDraftJsFns from '../../hooks/useDraftJsFns';
import ModalsWrapper from './ModalsWrapper';
import { AnimatePresence } from 'framer-motion';
import { IReplacementWord } from '../../api/ReplacementsService';
import { debounce } from 'lodash';
import {
  createNewEditorContentVersion,
  deleteEditorContent,
  deleteRecording,
  saveEditorContent,
} from '../../api/SessionsService';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import CustomEditor from './CustomEditor';
import { convertToHTML } from 'draft-convert';
import { useSnackbar } from 'notistack';

export const covertEditorStateToHTML = (editorState: EditorState) => {
  let counter = 0;
  let blockSpeakers = new Set();
  let blockSpeakersArrInit: any[] = [];

  // Currentlyjust using convertToHTML only to get styles efficiently out and then adding speakers after
  const html = convertToHTML({
    styleToHTML: (style) => {
      if (style === 'BOLD') {
        return <span style={{ fontWeight: 'bold' }} />;
      }
      if (style === 'ITALIC') {
        return <span style={{ fontStyle: 'italic' }} />;
      }
      if (style === 'UNDERLINE') {
        return <span style={{ textDecoration: 'underline' }} />;
      }
    },
    blockToHTML: (block) => {
      if (block.type === 'customBlock') {
        counter++;

        if (block.data?.speaker && counter % 2 !== 0) {
          blockSpeakers.add(block.data.speaker);
          blockSpeakersArrInit.push(block.data.speaker);
        }
        return <p />;
        //   <div>
        //     <span>{block.data?.speaker?.name}</span>
        //     <span>{block.text}</span>
        //   </div>
        // );
      }
    },
    entityToHTML: (entity, originalText) => {
      // if (entity.type === 'WORD') {
      //   TO-DO: if we want to add any additional stuff to our word entities
      // }
      return originalText;
    },
  })(editorState.getCurrentContent());

  if (blockSpeakersArrInit.length) {
    let prevSpeaker: any = null;

    const paragraphsHtml = html
      .split('</p>')
      .slice(0, -1)
      .map((p, i) => {
        const speaker = blockSpeakersArrInit[i];

        const prevBlockIsSame =
          speaker && prevSpeaker !== null && prevSpeaker && prevSpeaker.id
            ? prevSpeaker.id === speaker.id
            : false;

        prevSpeaker = speaker;

        if (!prevBlockIsSame) {
          const spakerString = `<h1>${speaker.name}</h1>`;
          return `${spakerString}${p}</p>`;
        }
        return `${p}</p>`;
      })
      .join('');

    return paragraphsHtml;
  }

  return html;
};

const newSpeaker: ISpeaker = {
  name: 'Neznani govorec',
  id: -1,
  labels: [],
};

const Editor = () => {
  const {
    setLiveWordData,
    setCursor,
    setFixSpellCommandMode,
    setFindCommandMode,
    liveWordData,
    transcriptData,
    footerTr,
    cursor,
    editorState,
    setEditorState,
  } = useEditor();

  const speakersDefined = useRef(false);
  const [showSpeakers, setShowSpeakers] = useState(false);

  const [modal, setModal] = useState<IModal>({ show: false, variant: ModalVariantsEnums.EXIT });
  const savedModalVariant = useRef<ModalVariantsEnums>(ModalVariantsEnums.EXIT);
  const [audioCanPlay, setAudioCanPlay] = useState<boolean>(false);
  const [willRecordInActiveSessionId, setWillRecordInActiveSessionId] = useState<number | null>(null);
  const [lastSaved, setLastSaved] = useState<ILastSaved>({
    lastSaveSuccess: true,
    lastSavedTs: null,
    isSaving: false,
  });
  const [showEditor, setShowEditor] = useState<boolean>(true);
  const [spellCheck, setSpellCheck] = useState<boolean>(false);
  const [dictionaryModalProps, setDictionaryModalProps] = useState<IDictionaryModalProps>({
    open: false,
    word: undefined,
  });
  const [replaceWordsModalProps, setReplaceWordsModalProps] = useState<IReplaceWordsModalProps>({
    selectedPhrase: [],
    se: null,
    selection: null,
  });
  const [loopModeOptions, setLoopModeOptions] = useState<ILoopModeOpts>({
    isActive: false,
    startTime: 0,
    endTime: 0,
  });
  const [loadingEditorDetails, setLoadingEditorDetails] = useState(false);
  const [canDiscard, setCanDiscard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const validRedirect = useSelector((state: IStore) => state.validRedirect);
  const speakerModal = useSelector((state: IStore) => state.speakersModal);
  const updateEditorState = useSelector((state: IStore) => state.manuallyUpdateEditorState);
  const headerIsExtended = useSelector((state: IStore) => state.headerIsExtended);
  const editorMode = useSelector((state: IStore) => state.editorMode);
  // const currentTime = useSelector((state: IStore) => state.currentTime);
  const fontSize = useSelector<IStore, string>((state) => state.fontSize);
  const tbFormat = useSelector((state: IStore) => state.tbFormat);
  const showConfidence = useSelector((state: IStore) => state.showConfidence);
  const loadContentToEditor = useSelector((state: IStore) => state.loadContentToEditor);
  const isSessionDiscarded = useSelector((state: IStore) => state.isSessionDiscarded);

  const history = useHistory();
  const dispatch = useDispatch();
  const { selectionPopoverProps, closeSelectionPopover } = useSelectionPopover(showEditor);
  const { isAvailable: currentModelAvailable, fetchCurrentModelStatus } = useCheckIfModelAvailable();
  const { editorRef, topScrollerRef, isInFindMode, isNewRecordingSession, hasEditorContentChanged } =
    useEditorRefs();
  const {
    resetLastMessage,
    closeWs,
    lastSessionInfo,
    resetUpdateAudioToken,
    recordingsIdsInCurrentEditorSession,
  } = useWs();
  const { startRecordingLive, stopRecordingLive, releaseAudioContext } = useAudioSocket();

  const savedEditorContent = useRef<any>(null);
  const lastEditorState = useRef<EditorState | null>(null);
  const lastWillRecordInActiveSessionId = useRef<number | null>(null);
  const lastSessionId = useRef<number | null>(null);
  const editorContentId = useRef<number | null>(null);

  const { handleBeforeInput, onEditorChange, handlePastedText } = useDraftJsFns({
    editorState,
    setEditorState,
    hasEditorContentChanged,
  });

  const saveCurrentSession = useCallback(
    debounce(
      async ({
        liveWordData,
        editorState,
        sessionId,
      }: {
        liveWordData?: IWordDataLive;
        editorState?: EditorState;
        sessionId?: number | null;
      }) => {
        if (savedModalVariant.current === ModalVariantsEnums.DISCARD || !hasEditorContentChanged.current) {
          return;
        }

        setLastSaved((prev) => {
          return {
            ...prev,
            isSaving: true,
          };
        });

        try {
          if (sessionId && editorState) {
            const r = !editorContentId.current
              ? await createNewEditorContentVersion(
                  JSON.stringify({ rawContentState: convertToRaw(editorState.getCurrentContent()) }),
                  sessionId
                )
              : await saveEditorContent(
                  JSON.stringify({ rawContentState: convertToRaw(editorState.getCurrentContent()) }),
                  editorContentId.current
                );

            if (
              (editorContentId.current && r.status === 204) ||
              (!editorContentId.current && r.status === 201)
            ) {
              setLastSaved({
                lastSavedTs: Date.now(),
                isSaving: false,
                lastSaveSuccess: true,
              });

              const { id } = r.data;
              if (typeof id === 'number') {
                setCanDiscard(true);
                editorContentId.current = id;
              }
            } else {
              // handle not saved
              setLastSaved((prev) => {
                return {
                  ...prev,
                  isSaving: false,
                  lastSaveSuccess: false,
                };
              });
            }
          }

          hasEditorContentChanged.current = false;
        } catch (e) {
          setLastSaved((prev) => {
            return {
              ...prev,
              isSaving: false,
              lastSaveSuccess: false,
            };
          });
        }
      },
      2500
    ),
    []
  );

  const isInitMount = useRef(true);
  const initEditorState = useRef<EditorState | null>(null);

  useEffect(() => {
    if (
      editorMode === EditorModeEnums.RECORDING_MODE ||
      editorMode === EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
    ) {
      initEditorState.current = editorState;
      isInitMount.current = false;
    }
  }, [editorMode]);

  useEffect(() => {
    if (isInitMount.current && editorState.getCurrentContent().getPlainText() === '') {
      return;
    }
    if (isInitMount.current) {
      initEditorState.current = editorState;
      isInitMount.current = false;
    }

    if (!initEditorState.current) return;

    if (
      initEditorState.current.getCurrentContent().getPlainText() ===
      editorState.getCurrentContent().getPlainText()
    )
      return;

    if (
      (modal.variant === ModalVariantsEnums.EXIT || modal.variant === ModalVariantsEnums.DISCARD) &&
      modal.show === true
    )
      return;

    initEditorState.current = editorState;
    setLastSaved((prev) => {
      return {
        ...prev,
        isSaving: true,
      };
    });

    saveCurrentSession({
      editorState: editorState,
      sessionId:
        willRecordInActiveSessionId !== null ? willRecordInActiveSessionId : lastSessionInfo?.sessionId,
    });
  }, [editorState, lastSessionInfo?.sessionId, willRecordInActiveSessionId]);

  const handleAutoSave = (editorState: EditorState) => {
    initEditorState.current = editorState;
    setLastSaved((prev) => {
      return {
        ...prev,
        isSaving: true,
      };
    });

    saveCurrentSession({
      editorState: editorState,
      sessionId:
        willRecordInActiveSessionId !== null ? willRecordInActiveSessionId : lastSessionInfo?.sessionId,
    });
  };

  useEffect(() => {
    return () => {
      recordingsIdsInCurrentEditorSession.current = [];
      savedEditorContent.current = null;
      releaseAudioContext();
      closeWs();
      dispatch(setEditorMode(null));
      dispatch(setAnimButtonState(AnimButtonStatesEnum.NORMAL));
      dispatch(setUploadedFileDuration(null));
      resetLastMessage();
      resetUpdateAudioToken();
      dispatch(setValidRedirect(false));
      dispatch(setAudioInfo({ url: '', loadNew: false }));
      dispatch(setSessionName(''));
      dispatch(setIsSessionDiscarded(false));
      dispatch(setTbFormat({}));
      dispatch(setShowConfidence(false));
      dispatch(setClickedTime(null));
      dispatch(setHeaderIsExtended(true));
      dispatch(
        setLoadContentToEditor({
          recFinishedStartLoadingNewEditorState: false,
          recStartedLoadTextFromEditor: false,
        })
      );
      dispatch(setCurrentTime(0));
    };
  }, []);

  useEffect(() => {
    const { update, newText, rangeToReplace, entityToMerge, blockKey } = updateEditorState;

    if (!update || !newText || !rangeToReplace || !entityToMerge) return;

    dispatch(
      setManuallyUpdateEditorState({
        update: false,
      })
    );

    const newState = replaceSelectionWithNewText(
      editorState,
      editorState.getSelection(),
      newText,
      rangeToReplace,
      entityToMerge,
      blockKey
    );

    newState.getCurrentContent().mergeEntityData(entityToMerge, {
      text: newText,
      updatedText: newText,
      metadata: { source: undefined, original: undefined },
    });
    setEditorState(newState);
  }, [updateEditorState]);

  useEffect(() => {
    if (!selectionPopoverProps.anchorRect) {
      setLoopModeOptions({
        isActive: false,
        startTime: 0,
        endTime: 0,
      });
    } else {
    }
  }, [selectionPopoverProps]);

  useEffect(() => {
    if (editorMode !== EditorModeEnums.EDIT_MODE) return;
    fetchCurrentModelStatus();
  }, [fetchCurrentModelStatus, editorMode]);

  // Load data into Draft.js
  useEffect(() => {
    // Load editor state after upload
    if (
      editorMode === EditorModeEnums.TB_UPLOAD_MODE &&
      editorState.getCurrentContent().getPlainText() === ''
    ) {
      const { editorStateStringified, editorState, wordData, sessionId, transcript, rawContentState } =
        tbFormat;

      if (sessionId) {
        setWillRecordInActiveSessionId(sessionId);
      }

      if (editorStateStringified) {
        const newState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(editorStateStringified)),
          decorator
        );
        setEditorState(newState);
        savedEditorContent.current = newState;
      } else if (rawContentState) {
        const newState = EditorState.createWithContent(convertFromRaw(rawContentState), decorator);
        setEditorState(newState);
        savedEditorContent.current = newState;
      } else if (editorState) {
        setEditorState(editorState);
        savedEditorContent.current = editorState;
      } else if (wordData) {
        const newData: IFinalBlock[] = wordData.map((words) => {
          return { words, speaker: null };
        });
        const newEditorState = convertTranscriptToEditorState(undefined, false, newData);
        setEditorState(newEditorState);
        savedEditorContent.current = newEditorState;
      } else if (transcript) {
        const newData: IFinalBlock[] = transcript.map((words) => {
          return { words, speaker: null };
        });
        const newEditorState = convertTranscriptToEditorState(undefined, false, newData);
        setEditorState(newEditorState);
        savedEditorContent.current = newEditorState;
      } else {
        history.push('/');
        return;
      }

      setShowEditor(true);
      dispatch(setEditorMode(EditorModeEnums.EDIT_MODE));
    }
  }, [editorMode]);

  useEffect(() => {
    if (!showEditor && editorRef.current) {
      //TO-DO: handle blur
      editorRef.current.blur();
    }

    if (showEditor && topScrollerRef.current) {
      //TO-DO: handle scrolling
      // topScrollerRef.current.scrollIntoView();
    }
  }, [showEditor, editorRef, topScrollerRef]);

  const isEditorFocused = useRef(false);

  const [editorSelectionUpdateOnMount, setEditorSelectionUpdateOnMount] = useState<{
    offset: number;
    blockKey: string;
  } | null>(null);

  useEffect(() => {
    if (loadContentToEditor.recFinishedStartLoadingNewEditorState) {
      setLoadingEditorDetails(true);
      dispatch(
        setLoadContentToEditor({
          ...loadContentToEditor,
          recFinishedStartLoadingNewEditorState: false,
        })
      );

      setFixSpellCommandMode({
        currentCommandMode: CurrentCommandModeEnums.INIT,
        isSpellingOn: false,
        currentSpelledWord: [],
        selectedWordOriginalText: null,
        selectedWordIndexes: null,
        textToFetch: '',
        numberOfWordsInFixMode: 1,
        prevWordsHistory: null,
      });
      setFindCommandMode({
        findCommandModeOn: false,
        selectedWordIndexes: null,
        searchedText: null,
        selectedWordData: null,
        selectedRangeLength: 1,
      });
      isInFindMode.current = false;

      let newEditorState = convertTranscriptToEditorState(
        undefined,
        false,
        liveWordData.finalsBlocks,
        transcriptData,
        false,
        editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE
      );

      if (cursor.cursorPosition) {
        const finalW = cursor.cursorPosition[1];
        let offset = 0;

        liveWordData.finalsBlocks[cursor.cursorPosition[0]].words.forEach((w, i) => {
          if (i <= finalW) {
            const fT = w.updatedText || w.text;
            offset += fT.length + (w.spaceBefore ? 1 : 0);
          }
        });
        setEditorSelectionUpdateOnMount({
          offset,
          blockKey: newEditorState.getCurrentContent().getBlocksAsArray()[cursor.cursorPosition[0]].getKey(),
        });
      }

      setCursor((curr) => {
        return {
          ...curr,
          cursorPosition: null,
        };
      });

      setEditorState(newEditorState);
      setShowEditor(true);
      setLoadingEditorDetails(false);
    }

    if (loadContentToEditor.recStartedLoadTextFromEditor) {
      setLoadingEditorDetails(true);
      dispatch(setLoadContentToEditor({ ...loadContentToEditor, recStartedLoadTextFromEditor: false }));

      const sel = editorState.getSelection();
      const lastBlock = editorState.getCurrentContent().getLastBlock();

      const selBlock = sel.getAnchorKey();
      const pos = sel.getAnchorOffset();
      const bl = lastBlock.getText().length;
      const cursorIsAtLastBlock = lastBlock.getKey() === selBlock;
      const cursorisAtEnd = pos === bl;
      const isLastBlockAtEnd = cursorIsAtLastBlock && cursorisAtEnd;
      const isCollapsed = sel.isCollapsed();
      const shouldSetNewCursorPos = !isCollapsed ? false : isLastBlockAtEnd ? false : true;

      // TO-DO: test all the edge cases, where user start re-recording from beggining of paragraph
      // TO-DO: fix cursot at the beginning of text edge case, where selection is the same as when editor is blurred
      const firstBlock = editorState.getCurrentContent().getFirstBlock();
      const cursorAtBeginningOfText = firstBlock.getKey() === selBlock && pos === 0;

      const { liveWordTranscript, newCursorPos } = convertEditorStateToTranscript(editorState, {
        toLiveWordData: true,
        setNewCursorPos: shouldSetNewCursorPos,
      });
      setLiveWordData({
        lastInterim: '',
        finalsBlocks: liveWordTranscript,
      });

      // TO-DO: fix cursot at the beginning of text edge case, where selection is the same as when editor is blurred
      setCursor(
        firstBlock && cursorAtBeginningOfText ? { ...newCursorPos, cursorPosition: null } : newCursorPos
      );

      setShowEditor(false);
      setLoadingEditorDetails(false);
    }
  }, [loadContentToEditor]);

  useEffect(() => {
    if (!modal.show) {
      setReplaceWordsModalProps({ se: null, selection: null, selectedPhrase: [] });
    }
  }, [modal]);

  const onCanPlayCallback = useCallback(
    (canpPlay: boolean) => {
      setAudioCanPlay(canpPlay);
    },
    [setAudioCanPlay]
  );

  const {
    duration,
    audioIsPlaying,
    setChangePlaybackRate,
    playbackRate,
    setDynamicPlaybackRate,
    togglePlayPause,
    pauseAudio,
    audioIsInError,
    audioObject,
  } = useAudioPlayer(onCanPlayCallback, loopModeOptions);

  const toggleRecording = useCallback(async () => {
    if (audioIsPlaying) {
      enqueueSnackbar('Za dosnemavanje najprej ustavite audio posnetek!', { variant: 'error' });
      return;
    }

    if (editorMode === EditorModeEnums.RECORDING_MODE) {
      await stopRecordingLive();
      isNewRecordingSession.current = true;
    } else if (editorMode === EditorModeEnums.EDIT_MODE || editorMode === EditorModeEnums.PLAYING_MODE) {
      await startRecordingLive({
        redirectToEditor: false,
        wsOptions: willRecordInActiveSessionId ? { sessionId: willRecordInActiveSessionId } : undefined,
      });
      isNewRecordingSession.current = true;
      dispatch(setCurrentTime(0));
    }
  }, [
    audioIsPlaying,
    editorMode,
    enqueueSnackbar,
    stopRecordingLive,
    isNewRecordingSession,
    startRecordingLive,
    willRecordInActiveSessionId,
    dispatch,
  ]);

  const toggleSpellCheck = useCallback(() => {
    setSpellCheck((curr) => !curr);
    dispatch(setShowConfidence(!showConfidence));
  }, [setSpellCheck, dispatch, showConfidence]);

  useShortcuts({
    toggleRecording,
    togglePlayPause,
    toggleSpellCheck,
    setDynamicPlaybackrate: setDynamicPlaybackRate,
    playbackRate,
    editorMode: editorMode || EditorModeEnums.EDIT_MODE,
    duration,
    playing: audioIsPlaying,
    exception: modal.show,
  });

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push('/');
    }
  }, [history]);

  useBeforeunload((event) => event.preventDefault());

  // NEEDS UPDATING FOR MAKE BLOCKS
  // useEffect(() => {
  //   if (editorMode !== EditorModeEnums.TB_UPLOAD_MODE) return;
  //   if (!duration) return;
  //   if (!(duration / 60 > 15)) return;
  //   if (!makeBlocks && tbFormat.transcript.length <= 1) {
  //     setSuggestBlocks(true);
  //   }
  // }, [duration, makeBlocks, tbFormat.transcript.length, editorMode]);

  const handleLeaveEditor = useCallback(
    async (variant: ModalVariantsEnums) => {
      if (variant === ModalVariantsEnums.EXIT) {
        // await saveCurrentSession({
        //   editorState,
        //   sessionId:
        //     willRecordInActiveSessionId !== null ? willRecordInActiveSessionId : lastSessionInfo?.sessionId,
        // });

        setTimeout(() => {
          // Set audioIsPlaying MUST be false otherwise the audio will still play
          history.goBack();
        }, 200);
      } else if (variant === ModalVariantsEnums.DISCARD) {
        const { current: recordingIds } = recordingsIdsInCurrentEditorSession;
        if (editorContentId.current) {
          deleteEditorContent(editorContentId.current)
            .then((r) => {})
            .catch((e) => {
              console.log(e);
              //error
            });
        }

        savedModalVariant.current = variant;

        if (recordingIds.length > 0) {
          try {
            // const { current: initSavedEditorContent } = savedEditorContent;
            // // saveCurrentSession({
            //   editorState:
            //     initSavedEditorContent === null ? EditorState.createEmpty() : initSavedEditorContent,
            //   sessionId:
            //     willRecordInActiveSessionId !== null
            //       ? willRecordInActiveSessionId
            //       : lastSessionInfo?.sessionId,
            // });
            const r = await Promise.all(recordingIds.map((id) => deleteRecording(id)));

            if (r) {
              r.forEach((d) => {
                if (d.status === 204) {
                  //success
                }
              });
            }
          } catch (e) {
            // TO-DO: what to do in case of en error?
          }
        }

        pauseAudio();
        dispatch(setAudioInfo({ url: '', loadNew: false }));

        setTimeout(() => {
          // Set audioIsPlaying MUST be false otherwise the audio will still play
          history.goBack();
        }, 200);
      }
    },
    [history, dispatch, pauseAudio, recordingsIdsInCurrentEditorSession]
  );

  const handlePlayBackRate = useCallback(() => {
    setChangePlaybackRate(true);
  }, [setChangePlaybackRate]);

  const handleDynamicPlaybackRate = useCallback(
    (playbackRate: number) => {
      setDynamicPlaybackRate(playbackRate);
    },
    [setDynamicPlaybackRate]
  );

  const handlePlaybackRateHigher = useCallback(() => {
    setDynamicPlaybackRate((dynamicPlaybackRate) => dynamicPlaybackRate + 0.1);
  }, [setDynamicPlaybackRate]);

  const handlePlaybackRateLower = useCallback(() => {
    setDynamicPlaybackRate((dynamicPlaybackRate) => dynamicPlaybackRate - 0.1);
  }, [setDynamicPlaybackRate]);

  const handleAddToDictionaryClick = useCallback(
    (word: string | undefined) => {
      setModal({ show: true, variant: ModalVariantsEnums.ADD_TO_DICT });
      setDictionaryModalProps({ open: true, word });
    },
    [setModal, setDictionaryModalProps]
  );

  const handleShowReplaceWordsModal = useCallback(() => {
    const selection = editorState.getSelection();
    const se = getAllEntitiesKeysFromSelection(editorState, selection);

    const textToReplace: IReplacementWord[] = [];

    se.forEach((en, i) => {
      const d = en.entity.getData();

      textToReplace.push({ text: d.updatedText ? d.updatedText : d.text, spaceBefore: d.spaceBefore });
    });

    if (textToReplace.length > 0) {
      setReplaceWordsModalProps({ selectedPhrase: textToReplace, se, selection });
      setModal({ show: true, variant: ModalVariantsEnums.REPLACE_WORDS });
    }
  }, [setModal, editorState, setReplaceWordsModalProps]);

  const closeDictionaryModal = useCallback(
    (saved: boolean = false) => {
      setDictionaryModalProps({ open: false, word: undefined });
      setModal({ ...modal, show: false });
      if (saved) enqueueSnackbar('Shranjevanje uspešno!', { variant: 'success' });
    },
    [setDictionaryModalProps, dispatch, setModal]
  );

  const selectionPlayAudio = useCallback(() => {
    if (loopModeOptions.isActive) {
      togglePlayPause();
      return;
    }
    const selection = editorState.getSelection();
    let startEntity = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getAnchorKey())
      .getEntityAt(selection.getStartOffset());
    let endEntity = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getAnchorKey())
      .getEntityAt(selection.getEndOffset());

    if (!startEntity) {
      startEntity = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getAnchorKey())
        .getEntityAt(selection.getStartOffset() + 1);
    }
    if (!endEntity) {
      endEntity = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getAnchorKey())
        .getEntityAt(selection.getEndOffset() - 1);
    }

    if (endEntity && startEntity) {
      const startData = editorState.getCurrentContent().getEntity(startEntity).getData();
      const endData = editorState.getCurrentContent().getEntity(endEntity).getData();

      setLoopModeOptions({
        isActive: true,
        startTime: startData.startTime,
        endTime: endData.endTime,
      });
      togglePlayPause();
    } else {
      // dispatch snack
    }
  }, [editorState, dispatch, loopModeOptions, togglePlayPause]);

  const onTimeUpdate = useCallback(
    (time: number | null) => {
      if (editorMode !== EditorModeEnums.PLAYING_MODE && editorMode !== EditorModeEnums.EDIT_MODE) return;
      if (editorMode !== EditorModeEnums.PLAYING_MODE) {
        dispatch(setEditorMode(EditorModeEnums.PLAYING_MODE));
      }

      const { liveWordTranscript: trToSearch } = convertEditorStateToTranscript(editorState, {
        toLiveWordData: true,
      });

      if (trToSearch.length === 0) {
        dispatch(setClickedTime(time));
        return;
      }

      //let foundWord = false;
      if (!time) return;

      for (let i = 0; i < trToSearch.length; i++) {
        for (let j = 0; j < trToSearch[i].words.length; j++) {
          if (
            trToSearch[i].words[j].startTime !== undefined &&
            trToSearch[i].words[j].endTime !== undefined &&
            trToSearch[i].words[j].startTime <= time &&
            trToSearch[i].words[j].endTime >= time
          ) {
            const word = document.getElementById(
              (trToSearch[i].words[j].startTime + trToSearch[i].words[j].endTime).toFixed(4)
            );

            word &&
              word.scrollIntoView({
                block: 'center',
                inline: 'center',
              });
            dispatch(setClickedTime(time));
            return;
          }
          if (j !== 0 && trToSearch[i].words[j].startTime >= time) {
            const word = document.getElementById(
              (trToSearch[i].words[j].startTime + trToSearch[i].words[j].endTime).toFixed(4)
            );

            word &&
              word.scrollIntoView({
                block: 'center',
                inline: 'center',
              });
            dispatch(setClickedTime(time));
            return;
          }
        }
      }
      dispatch(setClickedTime(time));
    },
    [tbFormat, dispatch, editorMode, editorState]
  );

  // const currentTime = useSelector((state: IStore) => state.currentTime);
  // const currentWord = useMemo(() => {
  //   const currentWord = {
  //     startTime: 0,
  //     endTime: 0,
  //   };

  //   const contentState = editorState.getCurrentContent();
  //   const contentStateConvertEdToRaw = convertToRaw(contentState);
  //   const entityMap = contentStateConvertEdToRaw.entityMap;

  //   for (var entityKey in entityMap) {
  //     const entity = entityMap[entityKey];
  //     const word = entity.data;

  //     if (word.startTime <= currentTime && word.endTime >= currentTime) {
  //       currentWord.startTime = word.startTime;
  //       currentWord.endTime = word.endTime;

  //       const selected = document.getElementById((currentWord.startTime + currentWord.endTime).toFixed(4));

  //       selected &&
  //         selected.scrollIntoView({
  //           block: 'center',
  //           inline: 'center',
  //         });
  //     }
  //   }

  //   return currentWord;
  // }, [currentTime, editorState]);

  // const currentWord = getCurrentWord();
  // const unplayedColor = 'yellow';
  // const time = Math.round(currentTime * 4.0) / 4.0;

  // const forceRenderDecorator = (currState: EditorState) => {
  //   const contentState = currState.getCurrentContent();
  //   const decorator = currState.getDecorator();
  //   const newState = EditorState.createWithContent(contentState, decorator);
  //   const newEditorState = EditorState.push(newState, contentState, 'change-block-data');
  //   setEditorState(newEditorState);
  // };

  const replaceSpeaker = (
    newSpeaker: ISpeaker,
    oldSpeaker: ISpeaker,
    replaceAll: boolean,
    blockKey: string
  ) => {
    let oldRaw = convertToRaw(editorState.getCurrentContent());

    const startIndex = oldRaw.blocks.findIndex((b) => b.key === blockKey);

    if (startIndex === -1) return;

    for (let i = replaceAll ? 0 : startIndex; i <= oldRaw.blocks.length - 1; i++) {
      // if (i === startIndex) {
      //   oldRaw.blocks[i].data = {
      //     ...oldRaw.blocks[i].data,
      //     speaker: newSpeaker,
      //   };
      // }

      if (
        oldRaw &&
        oldRaw.blocks[i] &&
        oldRaw.blocks[i].data !== undefined &&
        oldRaw.blocks[i].data?.speaker !== undefined &&
        oldRaw.blocks[i].data?.speaker.id === oldSpeaker.id
      ) {
        oldRaw.blocks[i].data = {
          ...oldRaw.blocks[i].data,
          speaker: newSpeaker,
        };
      } else {
        if (!replaceAll) break;
      }
    }
    const newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');

    setEditorState(mergedState);
    return mergedState;
  };

  function addSpeakersToAllBlocks(editorState: EditorState, newSpeaker: ISpeaker) {
    let oldRaw = convertToRaw(editorState.getCurrentContent());

    oldRaw.blocks.forEach((b, i) => {
      if (!b.data || !b.data.speaker) {
        oldRaw.blocks[i].data = {
          ...oldRaw.blocks[i].data,
          speaker: newSpeaker,
          isFirstInRange: false,
        };
      }
    });

    // oldRaw.blocks.splice(index, 0, newBlock);
    const newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');

    const newSelState = EditorState.forceSelection(mergedState, editorState.getSelection());
    const ts = clearDraftSelection(newSelState, newSelState.getCurrentContent().getFirstBlock().getKey());
    handleAutoSave(ts);
    setEditorState(mergedState);
    return mergedState;
  }

  // function updateBlockData(editorState: EditorState, blockKey: string, newData: any) {
  //   let oldRaw = convertToRaw(editorState.getCurrentContent());

  //   const blockIndex = oldRaw.blocks.findIndex((b) => b.key === blockKey);

  //   if (blockIndex === -1) return editorState;
  //   oldRaw.blocks[blockIndex].data = { ...oldRaw.blocks[blockIndex].data, ...newData };

  //   // oldRaw.blocks.splice(index, 0, newBlock);
  //   const newContent = convertFromRaw(oldRaw);
  //   const mergedState = EditorState.push(editorState, newContent, 'change-block-data');
  //   setEditorState(mergedState);
  //   return mergedState;
  // }

  const toggleInlineStyles = useCallback(
    (inlineStyle: string) => {
      const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
      const currentStyle = newState.getCurrentInlineStyle();
      const stylesToAdd: string[] = [];
      currentStyle.flatMap((a) => {
        if (a) {
          stylesToAdd.push(a);
        }
        return a;
      });

      const selection = editorState.getSelection();
      const se = getAllEntitiesKeysFromSelection(editorState, selection);

      se.forEach((ent) => {
        newState.getCurrentContent().mergeEntityData(ent.entityKey, { inlineStyles: stylesToAdd });
      });

      handleAutoSave(newState);
      setEditorState(newState);
      if (editorRef) {
        editorRef?.current?.focus();
      }
    },
    [editorState, editorRef]
  );

  function insertSpeakerBlockBeforeSelectedBlock(
    editorState: EditorState,
    blockKeysRange: string[],
    newSpeaker: ISpeaker,
    untillLast?: boolean
  ) {
    let oldRaw = convertToRaw(editorState.getCurrentContent());
    const startIndex = oldRaw.blocks.findIndex((b) => b.key === blockKeysRange[0]);
    const endIndex = oldRaw.blocks.findIndex((b) => b.key === blockKeysRange[1]);

    for (let i = untillLast ? startIndex + 1 : startIndex; untillLast ? i <= endIndex : i <= endIndex; i++) {
      oldRaw.blocks[i].data = {
        ...oldRaw.blocks[i].data,
        speaker: newSpeaker,
        isFirstInRange: untillLast ? i === startIndex + 1 : oldRaw.blocks[i].key === blockKeysRange[0],
      };
    }

    const newContent = convertFromRaw(oldRaw);
    const mergedState = EditorState.push(editorState, newContent, 'change-block-data');

    return mergedState;
  }

  const splitBlockAtSpecificOffset = (
    editorState: EditorState,
    blockKey: string,
    offset: number,
    moveData?: boolean,
    addNewSpeakerData?: ISpeaker
  ) => {
    const contentState = editorState.getCurrentContent();

    const originalBlockData = contentState.getBlockForKey(blockKey).getData();
    const speakerData = originalBlockData.get('speaker');

    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: offset,
      focusKey: blockKey,
      focusOffset: offset,
      isBackward: false,
      hasFocus: false,
    });

    if ((speakerData && moveData) || addNewSpeakerData) {
      const newData = addNewSpeakerData
        ? originalBlockData.set('speaker', { ...addNewSpeakerData })
        : originalBlockData.set('isFirstInRange', false);

      const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), newSelection);
      const newBlockKey = splitContentState.getSelectionAfter().getStartKey();
      const newSelection1 = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: 0,
      });
      const a = Modifier.setBlockData(splitContentState, newSelection1, newData);
      const splitEditorState = EditorState.push(editorState, a, 'split-block');

      const newSelState = EditorState.forceSelection(splitEditorState, newSelection1);
      return newSelState;
    }

    const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), newSelection);

    const splitEditorState = EditorState.push(editorState, splitContentState, 'split-block');

    // to-do: return state/content && last/new created blockkey
    return splitEditorState;
  };

  useEffect(() => {
    return () => {
      lastEditorState.current &&
        saveCurrentSession({
          editorState: lastEditorState.current,
          sessionId:
            lastWillRecordInActiveSessionId.current !== null
              ? lastWillRecordInActiveSessionId.current
              : lastSessionId.current,
        });
    };
  }, []);

  useEffect(() => {
    lastEditorState.current = editorState;
  }, [editorState]);
  useEffect(() => {
    if (!willRecordInActiveSessionId) return;

    lastWillRecordInActiveSessionId.current = willRecordInActiveSessionId;
  }, [willRecordInActiveSessionId]);
  useEffect(() => {
    if (!lastSessionInfo?.sessionId) return;

    lastSessionId.current = lastSessionInfo?.sessionId;
  }, [lastSessionInfo?.sessionId]);

  useEffect(() => {
    if (!showSpeakers) return;
    speakersDefined.current = true;
    setShowSpeakers(false);
    handleAutoSave(editorState);
    addSpeakersToAllBlocks(editorState, newSpeaker);
  }, [showSpeakers]);

  const handleOpenAddSpeakerModal = () => {
    dispatch(
      setSpeakersModal({
        editorSelection: editorState.getSelection(),
        modalType: SpeakersModalTypeEnum.ADD_SPEAKER_TO_SELECTION,
        showModal: true,
        speaker: null,
        blockKey: null,
      })
    );
  };

  const clearDraftSelection = (es: EditorState, blockKey: string) => {
    const newSelection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: 0,
    });

    const newSelState = EditorState.forceSelection(es, newSelection);
    return newSelState;
  };

  const setSpeakerToSelection = (sel: SelectionState, speaker?: ISpeaker) => {
    if (!speaker) speaker = newSpeaker;
    const currSelection = sel || editorState.getSelection();
    const startBlockKey = currSelection.getStartKey();
    const endBlockKey = currSelection.getEndKey();
    const startOffset = currSelection.getStartOffset();
    const endOffset = currSelection.getEndOffset();
    const lastBlock = editorState.getCurrentContent().getBlockForKey(endBlockKey);
    const lastBlockLength = lastBlock.getLength();
    const endOffsetIsAtEnd = endOffset === lastBlockLength;
    const startOffsetIsAtBeginning = startOffset === 0;
    const isSameBlock = startBlockKey === endBlockKey;

    if (currSelection.isCollapsed()) return;

    if (endOffsetIsAtEnd && startOffsetIsAtBeginning) {
      const n = insertSpeakerBlockBeforeSelectedBlock(
        editorState,
        [isSameBlock ? startBlockKey : startBlockKey, endBlockKey],
        speaker
      );

      const newSelState = EditorState.forceSelection(n, editorState.getSelection());
      const ts = clearDraftSelection(newSelState, startBlockKey);
      setEditorState(ts);
      handleAutoSave(ts);
      return setShowSpeakers(true);
    } else if (startOffset === endOffset && !endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      // Never happens currenlty
    } else if (!endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      const newState1 = splitBlockAtSpecificOffset(editorState, endBlockKey, endOffset, true);
      const newState2 = splitBlockAtSpecificOffset(newState1, startBlockKey, startOffset, false, speaker);

      if (!isSameBlock) {
        const es = insertSpeakerBlockBeforeSelectedBlock(
          newState2,
          [startBlockKey, endBlockKey],
          speaker,
          true
        );

        const ts = clearDraftSelection(es, startBlockKey);
        setEditorState(ts);
        return setShowSpeakers(true);
      }

      const ts = clearDraftSelection(newState2, startBlockKey);
      setEditorState(ts);
      return setShowSpeakers(true);
    } else if (endOffsetIsAtEnd && !startOffsetIsAtBeginning) {
      const newState = splitBlockAtSpecificOffset(editorState, startBlockKey, startOffset, false, speaker);

      if (!isSameBlock) {
        const es = insertSpeakerBlockBeforeSelectedBlock(
          newState,
          [startBlockKey, endBlockKey],
          speaker,
          true
        );

        const ts = clearDraftSelection(es, startBlockKey);
        setEditorState(ts);

        return setShowSpeakers(true);
      }

      const ts = clearDraftSelection(newState, startBlockKey);
      setEditorState(ts);

      return setShowSpeakers(true);
    } else if (startOffsetIsAtBeginning && !endOffsetIsAtEnd) {
      const newState = splitBlockAtSpecificOffset(editorState, endBlockKey, endOffset, true);
      const es = insertSpeakerBlockBeforeSelectedBlock(
        newState,
        [startBlockKey, endBlockKey],
        speaker,
        false
      );

      const ts = clearDraftSelection(es, startBlockKey);

      setEditorState(ts);
      return setShowSpeakers(true);
    }
  };

  const onEntriesClick = useCallback(() => {
    setModal({ show: true, variant: ModalVariantsEnums.ENTRIES });
  }, []);

  useEffect(() => {
    if (speakerModal?.showModal) return setModal({ show: true, variant: ModalVariantsEnums.SPEAKERS });
    if (!speakerModal?.showModal || speakerModal === null)
      return setModal((curr) => {
        return { ...curr, show: false };
      });
  }, [speakerModal]);

  if (!validRedirect) return <Redirect to="/" />;

  return (
    <div style={{ minHeight: '100vh', height: '100%' }}>
      {/* <div style={{ position: 'absolute', left: 50, top: 50 }}>
        <button
          onClick={() => {
            covertEditorStateToHTML(editorState);
            const es = convertToRaw(editorState.getCurrentContent());
            console.log('EditorState:', es);
          }}
        >
          LOG
        </button>
      </div> */}
      <AnimatePresence>
        {modal.show && (
          <ModalsWrapper
            modal={modal}
            setModal={setModal}
            setSpeakerToSelection={setSpeakerToSelection}
            handleLeaveEditor={handleLeaveEditor}
            setEditorState={setEditorState}
            replaceSpeaker={replaceSpeaker}
            closeDictionaryModal={closeDictionaryModal}
            dictionaryModalProps={dictionaryModalProps}
            replaceWordsModalProps={replaceWordsModalProps}
            editorState={editorState}
          />
        )}
      </AnimatePresence>

      {selectionPopoverProps.anchorRect && (
        <SelectionPopover
          toggleInlineStyles={toggleInlineStyles}
          audioIsPlaying={audioIsPlaying}
          anchorRect={selectionPopoverProps.anchorRect}
          direction={selectionPopoverProps.direction}
          selectedText={selectionPopoverProps.selectedText}
          selectionPlayAudio={selectionPlayAudio}
          closeSelectionPopover={closeSelectionPopover}
          addToDictionary={handleAddToDictionaryClick}
          handleShowReplaceWordsModal={handleShowReplaceWordsModal}
          handleOpenAddSpeakerModal={handleOpenAddSpeakerModal}
          hideDict={selectionPopoverProps.hideDict || !currentModelAvailable}
        />
      )}

      <Header
        editorMode={editorMode}
        togglePlayPause={togglePlayPause}
        onEntriesClick={onEntriesClick}
        audioIsPlaying={audioIsPlaying}
        audioCanPlay={audioCanPlay}
        spellCheck={spellCheck}
        toggleSpellCheck={toggleSpellCheck}
        handlePlayBackRate={handlePlayBackRate}
        playbackRate={playbackRate}
        handleDynamicPlaybackRate={handleDynamicPlaybackRate}
        handlePlaybackRateLower={handlePlaybackRateLower}
        handlePlaybackRateHigher={handlePlaybackRateHigher}
        audioDuration={duration}
        audioIsInError={audioIsInError || isSessionDiscarded}
        audioIsInErrorMessage={isSessionDiscarded ? 'Posnetek ni na voljo, ker je bil zavržen' : undefined}
        sessionId={willRecordInActiveSessionId || lastSessionInfo?.sessionId}
      />
      {(loadingEditorDetails || (!audioCanPlay && !audioIsInError && audioObject)) &&
        editorMode !== EditorModeEnums.TRANSCRIBING_UPLOAD_MODE &&
        editorMode !== EditorModeEnums.RECORDING_MODE && (
          <LinearProgress
            style={{
              position: 'absolute',
              // bottom: 0,
              top: headerIsExtended ? 160 : 0,
              left: 0,
              right: 0,
              width: '100%',
              // backgroundColor: '#ffffff',
              // color: 'white',
            }}
            color="secondary"
          />
        )}

      <div
        className={`editor_container_main ${headerIsExtended ? 'header_extended' : ''}`}
        style={{
          fontSize: fontSize,
        }}
      >
        <div className="editor_wrapper_main" id="editor-wrapper-main">
          <div ref={topScrollerRef} />
          {/* ADDITION OPTION TO CHANGE WORD STYLES BASED ON CURR-TIME - LESS RERENDERS  */}
          {/* {editorMode === EditorModeEnums.PLAYING_MODE && (
            <style scoped>
              {`[data-start~="${currentWord && currentWord.startTime.toString()}"] { color: red !important;
              font-weight: bold !important; }`}
              {`[data-start="${currentWord && currentWord.startTime.toString()}"] { color: #333333 !important;
              font-weight: bold !important; }`}
              {`[data-start="${
                currentWord && currentWord.startTime.toString()
              }"]+span { color: red !important;
              font-weight: bold !important; }`}
            </style>
          )} */}

          {showEditor ? (
            <CustomEditor
              editorSelectionUpdateOnMount={editorSelectionUpdateOnMount}
              ref={editorRef}
              editorState={editorState}
              handlePastedText={handlePastedText}
              setEditorState={setEditorState}
              onEditorChange={onEditorChange}
              isEditorFocused={isEditorFocused}
              handleBeforeInput={handleBeforeInput}
              customBlockStyleFn={customBlockStyleFn}
              readOnly={editorMode === EditorModeEnums.RECORDING_MODE} // || audioIsPlaying can be removed currenlty and it works fine
            />
          ) : (
            <LiveEditor
              isNewRecordingSession={isNewRecordingSession}
              hasEditorContentChanged={hasEditorContentChanged}
            />
          )}
        </div>
      </div>

      <Footer
        toggleRecording={toggleRecording}
        setModal={setModal}
        onTimeUpdate={onTimeUpdate}
        duration={duration}
        footerTr={footerTr}
        editorState={editorState}
        handleLeaveEditor={handleLeaveEditor}
        lastSaved={lastSaved}
        canDiscard={canDiscard}
        sessionId={willRecordInActiveSessionId || lastSessionInfo?.sessionId}
      />
    </div>
  );
};

export default Editor;
