import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IAppliedFilter } from '../DashboardHeader/ISearch';
import { DashboardRefreshState, IStore } from '../../redux/store/IStore';
import { useSelector, useDispatch } from 'react-redux';
import HideIcon from '@material-ui/icons/DeleteSweepOutlined';
import {
  setDashboardFilterCommands,
  setDashboardFilters,
  setDashboardRefresh,
} from '../../redux/actions/actions';

import './../../styles/css/dashboard.css';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const AppliedFilters = () => {
  const appliedFilters = useSelector((state: IStore) => state.dashboardFilters) as IAppliedFilter[];
  const dispatch = useDispatch();

  const removeFilter = (filterIndex: number): void => {
    const newFilters = JSON.parse(JSON.stringify(appliedFilters)).filter(
      (_: IAppliedFilter, index: number) => index !== filterIndex
    );

    dispatch(setDashboardFilters(newFilters));
    dispatch(setDashboardRefresh(DashboardRefreshState.ZERO));
  };

  return (
    <div className="applied-filters-row table-container">
      <span className="applied-filters-row-tag">Filtri:</span>
      <div className="applied-filters-inner-wrapper">
        {appliedFilters.map((labelC, index) => (
          <div key={index} className="applied-filter-item">
            <span>{labelC.map(({ label, field }) => (field === 'value' ? `'${label}'` : `${label} `))}</span>
            <div className="applied-filter-remove" onClick={() => removeFilter(index)}>
              <CloseIcon fontSize="small" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Commands = () => {
  const dispatch = useDispatch();
  const dashboardFilterCommands = useSelector((state: IStore) => state.dashboardFilterCommands);

  return (
    <div className="applied-filters-row table-container" style={{ alignItems: 'center' }}>
      <span className="applied-filters-row-tag">Ukazi:</span>
      <div className="applied-filters-inner-wrapper">
        <Tooltip
          title={
            !dashboardFilterCommands.showDeletedSessions ? 'Pokaži zbrisane seje' : 'Skrij zbrisane seje'
          }
        >
          <IconButton
            aria-label="copy"
            onClick={() =>
              dispatch(
                setDashboardFilterCommands({
                  showDeletedSessions: !dashboardFilterCommands.showDeletedSessions,
                })
              )
            }
          >
            <HideIcon
              className="accordion-header-icon"
              style={{ color: !dashboardFilterCommands.showDeletedSessions ? '#2b8dff' : '' }}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default AppliedFilters;
