import React, { useState, useEffect, useCallback, useRef } from 'react';
import AutoSuggestInput from './AutoSuggestInput';
import SearchToken from './SearchToken';
import {
  FilterType,
  TypeFilters,
  UserFilters,
  AdminFilters,
  ISuggestionItem,
  IAppliedFilter,
  ValueRegexes,
  FilterOptions,
} from './ISearch';
import { DashboardRefreshState, IStore, UserRoleEnums } from '../../redux/store/IStore';
import { Slide } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Zoom from '@material-ui/core/Zoom';
import { useSelector, useDispatch } from 'react-redux';
import { setDashboardFilters, setDashboardRefresh } from '../../redux/actions/actions';

import './../../styles/css/search.css';
import useIsRole from '../../hooks/useIsRole';

interface IToken {
  label: string;
  field: string;
  filterType: FilterType;
  nextType: FilterType;
  expectedFields: number;
}

// Generates a search filter for the search term 'searchTerm'
const generateSearchFilter = (searchTerm: string): IAppliedFilter => {
  return [
    { label: 'Isci: ', field: 'search' },
    { label: searchTerm, field: 'value' },
  ] as IAppliedFilter;
};

const Search = () => {
  const [text, setText] = useState<string>('');
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [filterType, setFilteryType] = useState<FilterType>(FilterType.Generic);
  const [currentSuggestion, setCurrentSuggestion] = useState<ISuggestionItem[]>([]);
  const [regex, setRegex] = useState<RegExp | null>(null);
  const [strictMode, setStrictMode] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);
  const [tokenFilterLabel, setTokenFilterLabel] = useState<string | null>(null)
  const [availableFilters, setAvailableFilters] = useState<FilterOptions>(UserFilters);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isAdmin] = useIsRole(UserRoleEnums.ADMIN);
  const dispatch = useDispatch();
  const globalFilters = useSelector((state: IStore) => state.dashboardFilters) as IAppliedFilter[];

  const updateTokens = (newTokens: IToken[]) => {
    let filterType: FilterType = newTokens.length === 0 ? FilterType.Generic : newTokens[newTokens.length - 1].nextType
    setFilteryType(filterType)
    setTokens(newTokens)
  }
  //Callbacks
  const applyNewFilters = useCallback(
    (newFilters: IAppliedFilter[]): void => {
      if (newFilters.length === 0) return;

      const filters = JSON.parse(JSON.stringify(globalFilters)).concat(newFilters);
      dispatch(setDashboardFilters(filters));
      dispatch(setDashboardRefresh(DashboardRefreshState.ZERO));
    },
    [dispatch, globalFilters]
  );

  // We add a token, left of the input field
  const addToken = useCallback(
    (index: number) => {
      if (index <= -1) index = 0;
      else if (index >= currentSuggestion.length) index = currentSuggestion.length - 1;

      let nextType: FilterType;

      const field: string = currentSuggestion[index].field;
      const label: string = currentSuggestion[index].label;
      switch (filterType) {
        case FilterType.Generic:
          if (!availableFilters[field]) {
            console.debug('Filter not found!');
            return;
          }

          nextType = availableFilters[field].nextType;
          break;
        case FilterType.DateFilter:
        case FilterType.StringFilter:
        case FilterType.NumberFilter:
        case FilterType.EnumFilter:
          if (!TypeFilters[filterType]) {
            console.debug('Filter not found!');
            return;
          }
          nextType = TypeFilters[filterType][field].nextType;
          break;
        default:
          nextType = FilterType.Generic;
          break;
      }

      let newTokens = [...tokens]

      newTokens.push({
        label,
        field,
        nextType,
        expectedFields: filterType === FilterType.Generic ? 3 : 0,
        filterType: filterType,
      });

      const newFilterType = newTokens.length === 0 ? FilterType.Generic : newTokens[newTokens.length - 1].nextType
      const newRegex = ValueRegexes[newFilterType] ? ValueRegexes[newFilterType].regex : null
      const newStrictMode = TypeFilters[filterType]

      updateTokens(newTokens)
      setRegex(newRegex);
      setStrictMode(newStrictMode);
    },
    [currentSuggestion, filterType, availableFilters]
  );

  // We delete the last (first from right), if the user presses backspace.
  // By default, we set the input (setLastTokenVal) to the token value,
  // except if the user deletes the token by clicking
  const handleDeleteFirst = (setLastToken: boolean = true) => {
    if (tokens.length === 0) return;

    if (tokens.length > 1) {
      if (tokens[tokens.length - 2].field === 'createdAt') {
        if (tokens[tokens.length - 1].field === "from") {
          isDateFromSet.current = false;
        } else if (tokens[tokens.length - 1].field === "to") {
          isDateToSet.current = false;
        } else if (tokens[tokens.length - 1].field === "is") {
          isDateIsSet.current = false;
        } else {
          throw new Error("[setCurrentSuggestion]: Unknown date filter type ancestor format.")
        }
      }
    }

    let newTokens = [...tokens];
    const lastToken = newTokens.splice(-1, 1)[0];

    if (setLastToken) updateText(lastToken.label);

    updateTokens(newTokens);
  };

  // To delete all tokens: Used when: 1) X is pressed 2) Search is made
  const deleteAllTokens = () => {
    isDateFromSet.current = false;
    isDateToSet.current = false;
    isDateIsSet.current = false;
    updateTokens([]);
  }

  // We delete the last (first from right) token, if the user clicks on it.
  const tokenDelete = (index: number) => {
    if (index === tokens.length - 1) handleDeleteFirst(false);
  };

  // To apply filters
  const applyFilters = useCallback(
    (search: string) => {
      setIsShown(false);
      setIsOpened(false);

      let newFilters: IAppliedFilter[] = [];
      let c = 0;

      while (c < tokens.length) {
        const currToken = tokens[c];

        // If the token is apart of Generics (3 Column names)
        // Then we parse it and next "expectedFields" as one
        if (availableFilters[currToken.field]) {
          const numOfTokens = currToken.expectedFields;
          const newFilter = tokens.slice(c, c + numOfTokens).map(({ label, field }) => ({
            label,
            field,
          })) as IAppliedFilter;

          newFilters.push(newFilter);
          c += numOfTokens;
        }

        // If the token isn't a part of the Generic data (aka Column names)
        // it can only be a standalone search token
        else {
          newFilters.push(generateSearchFilter(currToken.label));
          c++;
        }
      }

      // If the user searches, the input isnt parsed as a token
      // so it must be handled separately
      if (search !== '') newFilters.push(generateSearchFilter(search));

      deleteAllTokens();
      applyNewFilters(newFilters);
    },
    [applyNewFilters, tokens, availableFilters]
  );


  // If we are searching (FilterType.Generic and no suggestions selected) we apply filters
  // If we are not searching (meaning completing an existing filter), we add a new token
  const confirmInput = useCallback(
    (label: string) => {
      if (filterType === FilterType.Generic) {
        applyFilters(label);
      } else if (filterType === FilterType.Date) {
        if (isDateFromSet.current && isDateToSet.current) {
          isDateFromSet.current = false
          isDateToSet.current = false
          let newTokens = [...tokens];
          newTokens.push({ label, field: 'value', nextType: FilterType.Generic, expectedFields: 0, filterType });
          newTokens[newTokens.length - 5].expectedFields = 5
          updateTokens(newTokens);
        } else if (isDateFromSet.current) {
          const item: ISuggestionItem[] = [TypeFilters[FilterType.DateFilter].to]
          console.log(item)
          let newTokens = [...tokens]
          newTokens.push({ label, field: 'value', nextType: FilterType.DateFilter, expectedFields: 0, filterType })
          updateTokens(newTokens);
          updateSuggestions(item)
        } else if (isDateToSet.current) {
          const item: ISuggestionItem[] = [TypeFilters[FilterType.DateFilter].from]
          console.log(item)
          let newTokens = [...tokens]
          newTokens.push({ label, field: 'value', nextType: FilterType.DateFilter, expectedFields: 0, filterType })
          updateSuggestions(item)
          updateTokens(newTokens);
        } else if (isDateIsSet.current) {
          isDateIsSet.current = false
          let newTokens = [...tokens];
          newTokens.push({ label, field: 'value', nextType: FilterType.Generic, expectedFields: 0, filterType });
          updateTokens(newTokens);
        } else {
          throw new Error('[confirmInput]: filter type Date should only be confirmed when one of the following is true: isDateFromSet isDateToSet isDateIsSet')
        }
      } else {
        if (label !== '') {
          let newTokens = [...tokens];
          newTokens.push({ label, field: 'value', nextType: FilterType.Generic, expectedFields: 0, filterType });
          updateTokens(newTokens);
        } else {
          applyFilters(label);
        }
      }
    },
    [filterType, applyFilters]
  );

  //Effects
  useEffect(() => {
    setAvailableFilters(isAdmin ? AdminFilters : UserFilters);
  }, [isAdmin]);

  // Every time filters change, we change the suggestions
  const isDateFromSet = useRef<boolean>(false)
  const isDateToSet = useRef<boolean>(false)
  const isDateIsSet = useRef<boolean>(false)
  useEffect(() => {
    if ((filterType === FilterType.String || filterType === FilterType.Enum) && tokens.length >= 2) {
      setTokenFilterLabel(tokens[tokens.length - 2].label)
    } else {
      setTokenFilterLabel(null)
    }
    setCurrentSuggestion(() => {
      switch (filterType) {
        case FilterType.Generic:
          return Object.values(availableFilters);
        case FilterType.DateFilter:
          return !isDateFromSet.current && !isDateToSet.current && !isDateIsSet.current ? Object.values({ ...TypeFilters[filterType] }) : currentSuggestion
        case FilterType.StringFilter:
        case FilterType.NumberFilter:
          return Object.values(TypeFilters[filterType]);
        case FilterType.EnumFilter:
          return Object.values(TypeFilters[filterType]);
        case FilterType.Date:
          if (tokens.length >= 2) {
            if (tokens[tokens.length - 1].field === "from") {
              isDateFromSet.current = true;
            } else if (tokens[tokens.length - 1].field === "to") {
              isDateToSet.current = true;
            } else if (tokens[tokens.length - 1].field === "is") {
              isDateIsSet.current = true;
            } else {
              throw new Error("[setCurrentSuggestion]: Unknown date filter type ancestor format.")
            }
          }
          return [];
        default:
          return [];
      }
    });

    // We limit the user to certain characters when he is typing filter values
    setRegex(ValueRegexes[filterType] ? ValueRegexes[filterType].regex : null);

    // We want to strictly limit the user to certain choices, when he is setting
    // the filter types (such as is, from, to, isbigger,...)
    setStrictMode(TypeFilters[filterType] ? TypeFilters[filterType] : false);
  }, [filterType, availableFilters, isShown]);

  // We delete all tokens when we close the menu
  useEffect(() => {
    if (!isShown) deleteAllTokens();
  }, [isShown]);

  const updateSuggestions = (codes: ISuggestionItem[]) => setCurrentSuggestion(codes)
  const toggleOpened = () => {
    if (isOpened) setIsShown(false)
    setIsOpened(!isOpened)
  }
  const enterer = () => setIsShown(true)

  const getSuffix = (value: string) => {
    switch (value) {
      case "c":
      case "č":
      case "f":
      case "h":
      case "k":
      case "p":
      case "s":
      case "š":
      case "t":
        return " s"
      case "b":
      case "d":
      case "g":
      case "j":
      case "l":
      case "m":
      case "n":
      case "r":
      case "v":
      case "z":
      case "ž":
      case "a":
      case "e":
      case "i":
      case "o":
      case "u":
        return " z"
      default: return "";
    }
  }
  const checkStartsWith = (value: string) => {
    let tokensCopy = [...tokens]
    const areAtLeastLength2 = tokensCopy.length > 1
    console.log(`areAtLeastLength2: ${areAtLeastLength2 ? "YES" : "NO"}`)
    if (!areAtLeastLength2) return;

    const currentToken = tokensCopy[tokensCopy.length - 1]

    if (currentToken.field !== 'startsWith') return;

    const currentLabelSplit = currentToken.label.split(" ")
    
    if (value.length === 0) {
      currentToken.label = `${currentLabelSplit[0]} ${currentLabelSplit[1]}` //I dont like this, but it works
    } else if (currentLabelSplit.length < 3) {
      console.log("value.substring(0, 1)")
      console.log(value.substring(0, 1))
      currentToken.label += getSuffix(value.substring(0, 1))
    }
    
    console.log(`currentToken`)
    console.log(currentToken)
    tokensCopy[tokensCopy.length - 1] = currentToken
    console.log("tokensCopy")
    console.log(tokensCopy)
    setTokens(tokensCopy)
  }
  const updateText = (value: string, shouldCheckStart?: boolean) => {
    if (shouldCheckStart) checkStartsWith(value)
    setText(value)
  }

  const isPreviousEnum = () => {
    if (tokens.length < 1) return false;

    const isEnumFilterType = tokens[tokens.length-1].filterType === FilterType.EnumFilter
    const isEnumType = tokens[tokens.length-1].filterType === FilterType.Enum

    return isEnumFilterType || isEnumType;
  }

  return (
    <>
      <Slide timeout={200} onEntered={enterer} in={isOpened} direction="left">
        <div className={`search-wrapper ${isShown ? '' : 'search-wrapper-hidden'}`}>
          {tokens.map((val, i) => (
            <SearchToken key={i} index={i} label={val.label} deleteCallback={tokenDelete}></SearchToken>
          ))}

          <AutoSuggestInput
            text={text}
            updateText={updateText}
            updateSuggestions={updateSuggestions}
            suggestions={currentSuggestion}
            canSearch={filterType === FilterType.Generic}
            deleteCallback={handleDeleteFirst}
            addTokenCallback={addToken}
            confirmCallback={confirmInput}
            regex={regex}
            tokenFilterLabel={tokenFilterLabel}
            strictMode={strictMode}
            setIsShown={setIsShown}
            isShown={isShown}
            previousTokenFilterField={tokens.length > 1 ? tokens[tokens.length - 1].field : null}
            filterType={filterType}
            isPreviousEnum={isPreviousEnum()}
          />
        </div>
      </Slide>

      <div className="search-big-icon-wrapper" onClick={toggleOpened}>
        <Zoom in={isOpened} style={{ position: 'absolute' }}>
          <CloseIcon fontSize="inherit" />
        </Zoom>
        <Zoom in={!isOpened} style={{ position: 'absolute' }}>
          <SearchIcon fontSize="inherit" />
        </Zoom>
      </div>
    </>
  );
};

export default Search;