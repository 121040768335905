import axios from 'axios';
import { setConfig, setRefetchTokens, setSttStatus, setUser } from '../redux/actions/actions';
import store from '../redux/store';
import { getTokens, removeTokens } from './AuthenticationService';

const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    const tokens = getTokens();

    const isTokenReq = typeof config.data === 'string' && config.data.includes('grant_type');

    if (tokens && tokens.accessToken && tokens.accessToken !== '' && !isTokenReq)
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // removeToken();
    // console.log('ERROR FROM AXIOS:', error, error.response);

    if (error.response && error.response.status === 401) {
      removeTokens();
      store.dispatch(setRefetchTokens(null));
      store.dispatch(setUser(null));
      store.dispatch(setConfig(null));
      store.dispatch(setSttStatus(null));
    }

    const customEvent = new CustomEvent('axios-error', { detail: error.response });
    // console.log(error.response);
    document.dispatchEvent(customEvent);

    return Promise.reject(error);
  }
);

export default instance;
