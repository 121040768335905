import React from 'react';
import Main from './components/Main/Main';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import useCheckTokenAndSetUser from './hooks/useCheckTokenAndSetUser';
import './styles/css/App.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import useLoadFonts from './hooks/useLoadFonts';
import useLocalStorage from './hooks/useLocalStorage';
import useAxiosErrorListener from './hooks/useAxiosErrorListener';

const App: React.FC = () => {
  useCheckTokenAndSetUser([], true);
  useLoadFonts();
  useLocalStorage();
  useAxiosErrorListener();

  return (
    <ErrorBoundary>
      <Main />
    </ErrorBoundary>
  );
};

export default App;
